import { Link, graphql } from 'gatsby';
import React from 'react';
import { Button, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import Content, { HTMLContent } from '../components/Content';
import DefaultPageLayout from '../components/DefaultPageLayout';

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
        heading: string;
        subheading: string;
        mainpitch: {
          image: { childImageSharp: { fluid: { src: string } } };
          header: string;
          text: string;
        };
      };
    };
  };
}

const ActionsRow = styled(Grid.Row)`
  margin-top: 100px;
`;

const ActionButton = styled(({ innerAs, ...rest }) => (
  <Button as={innerAs} {...rest} />
))`
  margin: 0.5em 0.25em 0.5em 0 !important;
`;

const MainpitchHeader = styled(Header)`
  font-size: 3em;
`;

const MainpitchText = styled.p`
  font-size: 1.33em;
`;

const MainpitchImage = styled(Image)`
  width: 200px !important;
  margin: 50px auto;
`;

export default ({ data }: Props) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <DefaultPageLayout
      title={frontmatter.title}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
    >
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={10}>
              <MainpitchHeader as="h3">
                {frontmatter.mainpitch.header}
              </MainpitchHeader>
              <MainpitchText>{frontmatter.mainpitch.text}</MainpitchText>
            </Grid.Column>
            <Grid.Column floated="right" textAlign="center" width={4}>
              <MainpitchImage
                src={
                  !!frontmatter.mainpitch.image.childImageSharp
                    ? frontmatter.mainpitch.image.childImageSharp.fluid.src
                    : frontmatter.mainpitch.image
                }
              />
            </Grid.Column>
          </Grid.Row>
          <ActionsRow>
            <Grid.Column textAlign="center">
              <ActionButton
                innerAs={Link}
                primary
                to="/course"
                size="huge"
                icon
                labelPosition="right"
              >
                Launch Course
                <Icon name="arrow right" />
              </ActionButton>
              <ActionButton
                secondary
                href="https://github.com/blove/advanced-rxjs/fork"
                size="huge"
                icon
                labelPosition="right"
              >
                Git Repository
                <Icon name="github" />
              </ActionButton>
            </Grid.Column>
          </ActionsRow>
        </Grid>
      </Segment>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row centered>
            <Grid.Column width={16}>
              <HTMLContent content={html} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </DefaultPageLayout>
  );
};

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-page" } }) {
      html
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          header
          text
        }
      }
    }
  }
`;
